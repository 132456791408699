<template class="fvh">
    <section class="bg-dark text-white fvh" style="padding-top: 60px;">
        <div class="row gx-5 justify-content-center">
                <!-- login card -->
                <div class="col-lg-6 col-xl-4">
                    <div class="card mb-5 mb-xl-0">
                        <div class="card-body p-5">
                            <div>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Username" v-model="user.username">
                                </div>
                                <div class="input-group" style="margin-top: 20px;">
                                    <input type="password" class="form-control" placeholder="Password" v-model="user.passhash">
                                </div>
                                <div class="d-grid" style="margin-top: 20px;"><a class="btn btn-primary" v-on:click="login(user)">Login</a></div>
                                <div class="register-text-style">No account? <a href="/#/profile">Register</a> now!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
</template>
<script>
    import axios from "axios";
    export default {
        name: 'Login',
        components: {},
        data: () => {
            return {
                user: {
                    username: '',
                    passhash: ''
                },
                logged_in: false
            }
        },
        mounted () 
        {
          let s_user = sessionStorage.getItem('user');
          if (s_user != null)
          {
            let session_user = JSON.parse(s_user);
            this.user.username = session_user.username;
            console.log("session user: ", session_user.username);
          }
        },
        methods: {
            login: (user) => 
            {
                console.log("attempting to login as: " + user.username);

                axios.post('/user/auth', user)
                .then(function (response) {
                    // extract response body
                    let user_obj = response.data;

                    // pre-cache the user
                    sessionStorage.setItem('user', JSON.stringify(user_obj));

                    let after_login_uri = sessionStorage.getItem("al_uri");
                    
                    // navigate to the home page 
                    let new_uri = "/#/";

                    if (after_login_uri != null) new_uri = after_login_uri;
                    window.location.href = new_uri;
                    window.location.replace(new_uri);
                    
              })
              .catch(function (error) {
                  alert("Login failed. Please check your username and password, and try again.");
                  
                  console.log(error);
              });
            }
        }
      }
</script>

<style scoped>
    .register-text-style {
        color: #1118;
        margin-top: 20px;
        text-align: center;
    }
    .input-style {
        border-radius: 3px;
        border-style: solid;
        border-color: #d3d3d3;
        color:#757474;
        border-width: 1px;
        /* width: 220px; */
        min-height: 26px;
        font-size: small;
    }
</style>