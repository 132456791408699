<template>
    <div>
        <section class="bg-grey text-white fvh" style="padding-top: 30px;">
            
        </section>
    </div>
</template>

<script>
import axios from "axios";
export default 
{
	name: 'Testing',
	components: {},
	data: function () {
		return {

		}
	},
	beforeMount () {
		// are we logged in?
		let s_user = sessionStorage.getItem('user');
		if (s_user != null)
		{
			let session_user = JSON.parse(s_user);
			this.user = session_user;
			console.log("session user: ", session_user.username);

		}

	},
	mounted () 
	{
		let state_data = localStorage.getItem("us_states");
		if (state_data != null)
		{
			let state_array = JSON.parse(state_data);
			this.us_states = state_array;
			console.log("loaded us states from localStorage()");
		}

		let country_data = localStorage.getItem("countries");
		if (country_data != null)
		{
			let country_array = JSON.parse(country_data);
			this.countries = country_array;
			console.log("loaded countries from localStorage()");
		}

	},
	methods: {
		create_order: function () {
            let key_data = ''; //crypto.randomBytes(16).toString("hex");
            let order_data = { };
            let user_id = -1;

            if (this.user && this.user.id) user_id = this.user.id;
            console.log(user_id);

			axios.post(`/user/${user_id}/orders`, { key: key_data, order: order_data })
			.then(function (response) {
				console.log(response.data);
			})
			.catch(function (error) {                  
				console.log(error);
			});			

		}

	}
}
</script>

<style>

</style>