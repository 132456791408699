<template class="fvh">
	<div>
		<section class="bg-grey text-white fvh" style="padding-top: 60px;">
			<div class="container">
				<!-- tube search & add -->
				<div class="row">
					<div class="col-md-5 auto-center">
						<div class="card mb-5 mb-xl-0">
							<div class="card-body p-5">
								<div class="input-group">
									<span class="label-style">Tube Number</span>
									<input type="text" id="txTubeNumber" class="bs tube-num-text" v-model="tube_number" :placeholder="default_hint" v-on:keyup="search($event, tube_number)" />
									<ul id="ulResult" class="filter-results" v-if="results_visible">
										<li v-for="tube in tubes" :key="tube.id" :class="tube.class" v-on:click="add(tube)">{{ tube.name }}</li>
									</ul>
									<input type="button" class="btn btn-primary add-button" v-on:click="add(selected_tube)" value="Add" />	
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- order card -->
				<div class="row" v-if="order_items.length > 0" style="margin-top: 30px;">
					<div class="col-md-10 auto-center">
						<div class="card mb-5 mb-xl-0">
							<div class="card-body p-5">
								<!-- header row-->
								<div class="row order-row order-text" style="background-color: transparent;">
									<div class="col-sm-1"></div>
									<div class="col-sm-2">Tube Type</div>
									<div class="col-sm-2">Test Type</div>
									<div class="col-sm-2">Manufacturer</div>
									<div class="col-sm-2">Notes</div>
									<div class="col-sm-1">Quantity</div>
									<div class="col-sm-2" style="text-align: right; padding-right: 35px;">Price</div>
								</div>
								<!-- order item rows -->
								<div class="input-group row order-row order-text" v-for="item in order_items" :key="item.key" v-on:click="item_click(item)">
									<div class="col-sm-1"><img src="assets/remove.png" class="remove-icon" v-on:click="remove(item)" /></div>
									<div class="col-sm-2">{{item.name}}</div>
									<div class="col-sm-2"> <!-- test -->
										<select class="tube-test-style" id="tests" v-model="item.selected_test">
											<option v-for="test in test_types" :key="'test_'+test.id" :value="test">{{test.name}}</option>
										</select>
									</div>
									<div class="col-sm-2"> <!-- manufacturer -->
										<select class="tube-test-style tube-manufacturer-style" id="manufacturers" v-model="item.selected_manufacturer">
											<option v-for="man in manufacturers" :key="'manf_'+man.id" :value="man">{{man.display_name}}</option>
										</select>
									</div>
									<div class="col-sm-2"><input type="text" class="notes-style" v-model="item.notes" /></div>
									<div class="col-sm-1"><input type="text" class="notes-style quantity-style" v-model="item.quantity" /></div>
									<div class="col-sm-2" style="text-align: right;"><span class="label-style price-style">{{get_item_price(item)}}</span></div>
								</div>
								<!-- subtotal -->
								<div class="row order-row tax-row order-text" style="border-top: 1px solid #c5c5c5;">
									<div class="col-sm-1 offset-7 summary-text">Subtotal</div>
									<div class="col-sm-1 offset-3">
										<span class="label-style price-style tax-text">{{format_currency(subtotal)}}</span>
									</div>
								</div>
								<!-- tax -->
								<div class="row order-row tax-row order-text">
									<div class="col-sm-1 offset-7 summary-text">Tax</div>
									<div class="col-sm-1 offset-3">
										<span class="label-style price-style tax-text">{{format_currency(tax)}}</span>
									</div>
								</div>
								<!-- total -->
								<div class="row order-row tax-row order-text">
									<div class="col-sm-1 offset-7 summary-text">Total</div>
									<div class="col-sm-1 offset-3">
										<span class="label-style price-style tax-text">{{format_currency(total)}}</span>
									</div>
								</div>
								<!-- checkout button -->
								<div class="row order-row tax-row order-text" style="background-color: transparent;">
									<div class="col-sm-2 offset-10">
										<input type="button" class="btn btn-primary checkout-button" value="Continue" />
									</div>
								</div>
							</div>
						</div>						
					</div>					
				</div>
			</div>
		</section>
	</div>
</template>
<!-- vue2 script section -->
<script>
	import axios from "axios";
	import crypto from "crypto";

	export default {
		name: 'Order',
		components: {},
		data: function ()
		{
			return {
				user: {
					username: '',
					passhash: ''
				},
				tubes: [],
				tube_types: [],
				test_types: [],
				order_items: [],
				selected_tube_class: 'selected-tube',
				normal_tube_class: 'normal-tube',
				results_visible: false,
				selected_test: { 
					price: 0.0 
				},
				selected_index: -1,
				default_hint: "e.g. 12AT7 or 6146",
				hint: "e.g. 12AT7 or 6146"
			}
		},
		beforeMount: function () 
		{
			// load tube data from local storage
			let s_tubedata = localStorage.getItem("tube_data");
			if (s_tubedata != null) {
				let tube_data = JSON.parse(s_tubedata);
				// mesh the stored tube data with the VM
				this.tubes = tube_data.tubes;
				this.tubes_original = tube_data.tubes;
				this.selected_index = -1;
				this.tube_types = tube_data.tube_types;
				this.test_types = tube_data.test_types;
				this.manufacturers = tube_data.manufacturers;
				// set the default selected tube
				this.selected_tube = tube_data.tubes[0];
				console.log("tube data loaded from localStorage()");
			}
						
			// load user from session storage
			let s_user = sessionStorage.getItem('user');
			if (s_user != null) {
				let session_user = JSON.parse(s_user);
				this.user = session_user;
				console.log("session user: ", session_user.email);
			} else {
				sessionStorage.setItem("al_uri", "/#/order");
				window.location.href = "/#/login";
			}

			let s_order = sessionStorage.getItem("CurrentOrder");
			if (s_order != null)
			{
				let orders = JSON.parse(s_order);
				console.log(orders);
				this.order_items = orders.order_items;
				this.recalculate();
			}

		},
		mounted: function () 
		{

		},
		methods: 
		{
			
			search: function(evt, tube_number) 
			{
				switch (evt.keyCode) {
					case 38: // up
						this.selected_index--;
						if (this.selected_index < 0) this.selected_index = 0;
						this.selected_tube = this.tubes[this.selected_index];
						this.autocomplete_select(this.selected_tube);
						return;
					case 40: // down
						this.selected_index++;
						if (this.selected_index > (this.tubes.length-1)) this.selected_index = this.tubes.length-1;
						this.selected_tube = this.tubes[this.selected_index];
						this.autocomplete_select(this.selected_tube);
						return;
					case 13:
						this.add(this.selected_tube);
						return;
				}
				if (tube_number == '')
				{
					this.tubes = this.tubes_original;
					this.results_visible = false;
					return;
				}
				let cx = this;
				axios.get(`/tube/search/${tube_number}`)
				.then(function (response) {
					cx.tubes = response.data;
					// set the selected tube to the first tube
					cx.selected_tube = response.data[0];
					cx.selected_index = -1;
					cx.results_visible = true;
				})
				.catch(function (error) {
					console.log(error);
				});				
			},

			select_tube: function(tube) 
			{
				// select the tube in the list
				this.autocomplete_select(tube);
				// set the selected tube
				this.selected_tube = tube;
				// set tube number
				this.tube_number = tube.name;
				// hide the autocomplete result
				this.results_visible = false;
				// force the UI to do things
				this.$forceUpdate();
			},

			autocomplete_select: function(tube) 
			{
				this.tubes.forEach((tube2) => { 
					// if the id's dont match, use the regular class
					if (tube2.name != tube.name) { tube2.class = "normal-tube"; }
					// if they do match set the current tube to have the selected class 
					else { tube2.class = "selected-tube"; }
				});
				// force the UI to do things
				this.$forceUpdate();											
			},

			add: function (t) 
			{
				// bail if nothing is selected
				if (t == null) return;
				// create order item (row)
				let tube = {
					// invoice line item #
					num: (this.order_items.length + 1),
					// key for order item
					key: this.get_uuid(),
					// tube type id
					type_id: t.id,
					// selected tube name (optional)
					name: t.name,
					// initial tube quantity (this line item)
					quantity: 1,
					// sets the default test type to the first type
					selected_test: this.test_types[0],
					// sets the default manufacturer to mixed / unspecified
					selected_manufacturer: this.manufacturers[5]
				}
				this.order_items.push(tube);
				// clear the tube number
				this.tube_number = '';
				// null the selected tube
				this.selected_tube = null;
				// reset the kbd list selection
				this.selected_index = -1;
				// hide the autocomplete results
				this.results_visible = false;
				// clear the hint since the user has added a tube successfully.
				this.default_hint = "";
				// recalculate the totals
				this.recalculate();
			},

			remove: function(item) 
			{
				if (window.confirm("Are you sure you want to remove this item from your order?") == true)
				{
					let found_at_index = 0;
					for(var x = 0; x < this.order_items.length; x++) {
						let order_item = this.order_items[x];
						if (order_item.key == item.key) {
							found_at_index = x;
						}
					}
					this.order_items.splice(found_at_index, 1);
					this.default_hint = (this.order_items.length < 1 ? this.hint : "");
				}
			},

			get_uuid: function() 
			{
				let b = [crypto.randomBytes(2).toString("hex"), crypto.randomBytes(6).toString("hex"), crypto.randomBytes(2).toString("hex"), crypto.randomBytes(2).toString("hex")];
				let s = `${b[0]}-${b[1]}-${b[2]}-${b[3]}`;
				return s;
			},

			item_click: function(item) 
			{
				console.log(item.name);
			},

			recalculate: function() 
			{
				this.subtotal = 0.0;
				for(var x = 0; x < this.order_items.length; x++)
				{
					let item = this.order_items[x];
					this.subtotal += item.selected_test.price*item.quantity;
				}
				this.tax = (this.subtotal * 0.0875);
				this.total = this.subtotal + this.tax;
				let fc_data = {
					order_items: this.order_items,
					subtotal: this.subtotal,
					tax: this.tax,
					total: this.total
				}
				sessionStorage.setItem("CurrentOrder", JSON.stringify(fc_data));
			},

			get_item_price: function(item) 
			{
				return this.format_currency(item.selected_test.price*item.quantity);
			},

			format_currency: function(value)
			{
				let locale = localStorage.getItem("locale");
				if (locale != null) locale = JSON.parse(locale);
				if (locale) {
					let us_fmt = { style: locale.style ,currency: locale.value };
					var formatter = new Intl.NumberFormat(locale.name, us_fmt);
					return formatter.format(value)					
				} else {
					return value;
				}				
			}

		}
	}
</script>
<!-- vue2 style section -->
<style>
	.tube-number-style {
		min-height: 32px;
		min-width: 106px;
		border-radius: 4px;
		color: #666;
	}

	.tube-type-style {
		min-height: 32px;
		min-width: 106px;
		color: #666;
		background-color: #fff;
	}

	.tube-test-style {
		min-height: 24px;
		min-width: 106px;
		font-size: 9pt;
		border: none;
		border-right-color: #c5c5c5;
		border-right-width: 1px;
		border-right-style: solid;
		color: #666;
		background-color: #fff;
	}

	.tube-num-text {
		font-size: 9pt;
		border: solid 1px #666;
	}

	.tube-manufacturer-style {
		min-width: 126px;
		color: #666;
		background-color: #fff;
	}

	.label-style {
		color: #888;
		margin-top: 8px;
		margin-right: 12px;
		font-size: 10pt;
		font-weight: 700;
	}

	.add-button {
		width: 80px;
	}

	.checkout-button {
		width: 120px;
		margin-top: 30px;
	}

	.auto-center {
		margin-left: auto;
		margin-right: auto;
	}

	.order-row {
		border: solid 1px #c5c5c5;
		border-bottom: 0px;
		background-color: #fff;
		width: 100%;
	}

	.tax-row {
		border: none
	}

	.tax-text {
		padding-left: 6px;
	}

	.order-text {
		color: #666;
		font-size: 9pt;
	}

	.notes-style {
		padding-left: 6px;
		border: none;
		height: 24px;
		color: #666;
		font-size: 9pt;
		border-radius: 2px;
		width: 140px;
	}

	.quantity-style {
		text-align: right;
		width: 80px;
	}

	.remove-icon {
		width: 16px;
		height: 16px;
		margin-left: -7px;
	}

	.filter-results {
		max-height: 255px;
		width: 170px;
		overflow: scroll;
		border: 1px solid #666;
		cursor: pointer;
		position: absolute;
		top: 36px;
		left: 96px;
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
		z-index: 999999;
		color: #333;
		background-color: #fff;
		font-size: 9pt;
	}

	.selected-tube {
		background-color: #0d6efd  !important;
		color: #fff;
	}

	.normal-tube {
		background-color: #fff;
		color: #666;		
	}

	.price-style {
		margin-top: 0px;
		font-size: 9pt;
		font-weight: 400;
	}

	.summary-text {
		font-size: 10pt;
		color: #222;
	}

</style>