<template>
  <div id="top">
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container px-5">
            <a class="navbar-brand text-nixie" href="/#/#top"><img src="assets/st-o.png" style="height: 48px;" /> Smith Thermionics</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item"><a class="nav-link" href="/#/#top">Home</a></li>
                    <!--<li class="nav-item"><a class="nav-link" href="/#/testing">Testing</a></li>-->
                    <li class="nav-item"><a class="nav-link" href="/#pricing">Pricing</a></li>
                    <li class="nav-item" v-if="!logged_in"><a class="nav-link" href="/#/profile">Register</a></li>
                    <li class="nav-item" v-if="logged_in"><a class="nav-link" href="/#/profile">Profile</a></li>
                    <li class="nav-item"><a class="nav-link" href="/#/contact">Contact</a></li>
                    <li class="nav-item" v-if="!logged_in"><a class="nav-link" href="/#/login">Login</a></li>
                    <li class="nav-item" v-if="logged_in"><a v-on:click="logout(this)" href="#" class="nav-link">Logout</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- SPA router outlet -->
    <router-view />
    <div id="lightbox" class="modal-lightbox">

    </div>  
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'app',
  components: {},
        data: () => {
            return {
                user: {
                    username: '',
                    passhash: ''
                },
                logged_in: false,
                tube_data: { },
                us_states: [ ],
                countries: [ ],
                default_country: { id: -1, name: 'not selected' }
            }
        },
        beforeMount () {

          let FORCE_CLEAR = false;
          if (FORCE_CLEAR) 
          { 
            localStorage.clear();
            sessionStorage.clear();
            console.log("FORCE cleared local & session storage");
          }

          this.load_us_states();
          this.load_countries();
        },
        mounted () 
        {
          this.get_tube_data(this);

          setInterval(() => {

            let s_user = sessionStorage.getItem('user');
            if (s_user != null)
            {
              let session_user = JSON.parse(s_user);
              this.user = session_user;
              this.logged_in = true;
            } else {
              this.logged_in = false;
            }            
          }, 500);
        },
        methods: {

          logout: () => {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = "/#/login";
            alert("You have been logged out.");
          },
          
          get_tube_data: (_this) => {
            axios.get('/tube_data')
              .then(function (response) {
                  _this.tube_data = response.data;
                  localStorage.setItem("tube_data", JSON.stringify(_this.tube_data));
                  console.log("downloaded newest tube data to localStorage() ... ");
              })
              .catch(function (error) {                  
                  console.log(error);
              });
          },

          load_us_states: () => {
            axios.get('/us_states')
              .then(function (response) {
                if (response.status == 200) {
                  localStorage.setItem("us_states", JSON.stringify(response.data));
                  console.log("donwloaded US state data ... ");                  
                } else {
                  console.log("problem loading US state data.");
                }

              })
              .catch(function (error) {                  
                //console.log(error);
                (error);
                console.log("problem loading US state data.");
              });
          },

          load_countries: function () {
            let cx = this;
            axios.get('/countries')
              .then(function (response) {
                if (response.status == 200) {
                  localStorage.setItem("countries", JSON.stringify(response.data));
                  console.log("donwloaded country data ... ");
                  cx.default_country = response.data[0];

                  let locale = {
                    name: cx.default_country.locale_name,
                    style: cx.default_country.locale_style,
                    value: cx.default_country.locale_value
                  };
                  
                  localStorage.setItem("locale", JSON.stringify(locale));

                  console.log(`default country/locale set to: `, cx.default_country.locale_name);                  
                } else {
                  console.log("problem loading country data.");
                }

              })
              .catch(function (error) {                  
                //console.log(error);
                (error);
                console.log("problem loading country data.");
              });
          }
        }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.vueperslide {
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);

}

.fvh {
  height: 100vh;
}

.modal-lightbox {
  height: 100vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: none;
  background-color: #00000099;
  z-index: 99999;
  position: absolute;
  top: 0;
  left: 0;
}

</style>
