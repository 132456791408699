<template class="fvh">
  <section class="bg-grey text-white fvh" style="padding-top: 60px;">
    
    <div class="d-none d-lg-block">
      <div class="row top-row">
        <div class="col-md-10 offset-2">
          <textarea class="comment-area box-style input-font" v-model="message__" placeholder="What can we do for you? "></textarea>
        </div> 
      </div>

      <div class="row bottom-row">
        <div class="col-md-3 offset-2">
          <div class="contact-info">Your Name</div><input type="text" v-model="name__" placeholder="" style="margin-left: 16px; width: 140px;" class="box-style input-font" />
        </div>

        <div class="col-md-3">
          <div class="contact-info">Your Contact Info</div><input type="text" v-model="contact__" placeholder="e-mail or phone number" style="margin-left: 16px; width: 192px;" class="box-style input-font" />
        </div>

        <div class="col-md-1 offset-1">
          <input type="button" class="btn btn-primary send-button" value="Send" v-on:click="send_message(message__, name__, contact__)" />
        </div>        
      </div>
    </div>

    <div class="mobile-nopad d-md-none">
      <div class="row">
        <div class="col-lg-6 col-xl-4">
          <div class="card mb-5 mb-xl-0 alt-card-body bg-grey">
              <div class="card-body p-5">
                <div class="small text-uppercase fw-bold">Your Message</div>
                <textarea class="comment-area-mobile box-style input-font" v-model="message__" placeholder="What can we do for you? "></textarea>
                
                <div class="small text-uppercase fw-bold">Your Name</div>
                <input type="text" v-model="name__" placeholder="" class="box-style input-font text-mobile" />
                
                <div class="small text-uppercase fw-bold">Your Contact Info</div>
                <input type="text" v-model="contact__" placeholder="e-mail or phone number" class="box-style input-font text-mobile" />
                <div>
                  <input type="button" v-if="send_enabled" class="btn btn-primary send-button" value="Send" v-on:click="send_message(message__, name__, contact__)" />
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>
    import axios from "axios";
    export default {
        name: 'Contact',
        components: {},
        data: () => {
            return {
              message__: '',
              name__: '',
              contact__: '',
              error_msg: "There was a problem sending your message. Please try again in a moment.",
              ok_msg: "Your message was sent. Thank you for contacting us. We will be in touch shortly!",
              send_enabled: true
            }
        },
        mounted () { 

        },
        methods: {
          send_message: function(msg, name, contact)
          {
            var that = this;
            if (msg == "" || name == "" || contact == "")
            {
              alert("Please fill out every field and try sending again.");
              return false;
            }
            this.send_enabled = false;
            let message_object = { "message": msg, "name": name, "contact": contact };
            axios.post('/contact/send', message_object)
                .then(function (response) {
                    if (response.status == 200) {
                      that.message__ = '';
                      that.name__ = '';
                      that.contact__ = ''; 
                      let new_uri = "/#/";
                      window.location.href = new_uri;
                      window.location.replace(new_uri);                                          
                      alert(that.ok_msg);
                    } else {
                      alert(this.error_msg);
                    }
              })
              .catch(function (error) {
                  eval(error);
                  alert(this.error_msg);
              });
          }
        }
      }
</script>

<style>

  .comment-area {
    width: 80%;
    height: 130px;
  }
  .comment-area-mobile {
    width: 96%;
    height: 130px;
  }

  .text-mobile {
    margin-left: 0px; 
    width: 96%;
  }

  .top-row {
    margin-top: 30px;
  }

  .bottom-row {
    margin-top: 20px;
  }

  .contact-info {
    display: inline-block;
    color: #ccc;
    font-size: 9pt;

  }

  .box-style {
    border-radius: 4px;
  }

  .send-button {
    width:96%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .tb-width {
    width: 150px;
  }

  .input-font {
    font-size: 10pt;
  }

  .alt-card-body {
    color: #ccc;
    font-weight: 500;
    font-size: 9pt;
  }

</style>