<template class="fvh">
	<section class="bg-grey text-white fvh" style="padding-top: 30px;">
		<!-- full-size layout -->
		<div class="d-none d-lg-block">
			<div class="input-area">
				<div class="input-card align-items-center ">

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">First name</span>
							<input type="text" class="input-style" v-model="user.firstname" />          
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Last name</span>
							<input type="text" class="input-style" v-model="user.lastname" />          
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Address line 1</span>
							<input type="text" class="input-style" v-model="user.address1" />          
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Address line 2</span>
							<input type="text" class="input-style" v-model="user.address2" />          
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">City</span>
							<input type="text" class="input-style" v-model="user.city" />          
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">State</span>
							<select class="input-style" style="min-width: 145px;" v-model="user.state_id">
								<option v-for="item1 in us_states" :key="'_US_'+item1.id" :value="item1.id">{{ item1.name }}</option>
							</select>         
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Country</span>
							<select class="input-style" style="min-width: 145px;" v-model="user.country_id">
								<option v-for="item2 in countries" :key="item2.country_id" :value="item2.country_id">{{ item2.country_name }}</option>
							</select>         
						</div>
					</div>

					<div class="row row-bottom">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Zip code</span>
							<input type="text" class="input-style" v-model="user.zipcode" />          
						</div>
					</div>

				</div>
				<div class="input-card align-items-center">

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Email address</span>
							<input type="text" :title="var_tooltip" v-on:blur="check_email(user.email)" :class="var_style" v-model="user.email" />       
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Phone number</span>
							<input type="text" class="input-style" v-model="user.phone" />          
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Billing address line 1</span>
							<input type="text" class="input-style" v-model="user.bill_address1" />          
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Billing address line 2</span>
							<input type="text" class="input-style" v-model="user.bill_address2" />          
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Billing City</span>
							<input type="text" class="input-style" v-model="user.bill_city" />          
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Billing State</span>
							<select class="input-style" style="min-width: 145px;" v-model="user.bill_state_id">
								<option v-for="item3 in us_states" :key="'_BS_'+item3.id" :value="item3.id">{{ item3.name }}</option>
							</select>         
						</div>
					</div>

					<div class="row">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Billing Country</span>
							<select class="input-style" style="min-width: 145px;" v-model="user.bill_country_id">
								<option v-for="item4 in countries" :key="item4.country_id" :value="item4.country_id">{{ item4.country_name }}</option>
							</select>         
						</div>
					</div>

					<div class="row row-bottom">
						<div class="col-sm-6 cell-style">
							<span class="label-style">Billing Zip code</span>
							<input type="text" class="input-style"  v-model="user.bill_zipcode"/>          
						</div>
					</div>

				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-sm-3 offset-7">
						<a class="btn btn-outline-light px-4" style="margin-left: 10px;" href="#" v-on:click="cancel()">Cancel</a>
						<a class="btn btn-primary px-4" style="margin-left: 20px;" href="#" v-on:click="save(user)">{{save_btn_text}}</a>	
					</div>
				</div>
			</div>
		</div>
		
		<!-- mobile layout-->
		<div class="mobile-nopad d-md-none">
			<div class="text-nixie" style="margin-left: auto; margin-right: auto; text-align: center;">Registration on mobile devices will be available on <span>4/18/2025</span></div>
		</div>
		
	</section>
</template>

<script>
import axios from "axios";
export default 
{
	name: 'Profile',
	components: {},
	data: function () {
		return {
			user: {
				id: 0,
				firstname: '',
				lastname: '',
				address1: '',
				address2: '',
				city: '',
				state_id: 0,
				country_id: 0,
				zipcode: '',
				bill_address1: '',
				bill_address2: '',
				bill_city: '',
				bill_state_id: 0,
				bill_country_id: 0,
				bill_zipcode: '',
				username: '',
				passhash: '',
				email: '',
				phone: '',
				notes: '',
				sec_level: 0
			},
			save_btn_text: 'Register',
			us_states: [],
			countries: [],
			email_is_available: true,
			var_style: 'input-style',
			var_tooltip: ''
		}
	},
	beforeMount () {
		// are we logged in?
		let s_user = sessionStorage.getItem('user');
		if (s_user != null)
		{
			let session_user = JSON.parse(s_user);
			this.user = session_user;
			this.save_btn_text = 'Update';
			console.log("session user: ", session_user.username);
		}
	},
	mounted () 
	{
		// load state data from session storage
		let state_data = localStorage.getItem("us_states");
		if (state_data != null)
		{
			let state_array = JSON.parse(state_data);
			this.us_states = state_array;
			console.log("loaded us states from localStorage()");
		}
		// load country data from session storage
		let country_data = localStorage.getItem("countries");
		if (country_data != null)
		{
			let country_array = JSON.parse(country_data);
			this.countries = country_array;
			console.log("loaded countries from localStorage()");
		}
	},
	methods: {
		save: function (_user) {
			let err_msg = "There was a problem saving your profile information. #1 Please try again.";
			axios.post(`/user/${_user.id}/profile`, { user: _user })
			.then(function (response) {
				if (response.status == 200)
				{
					let result = response.data;
					if (result != null)
					{
						if (result.objects_affected == 1)
						{
							sessionStorage.setItem('user', JSON.stringify(_user));
							alert("Your profile information was saved sucessfully.");
						}
						else if (result.objects_affected < 1)
						{
							err_msg = err_msg.replace("#1", "0 objects affected by action.")
							alert(`[E3]\r\n${err_msg}`)
						}					
					}
				}
				else { alert(`[E2]\r\n${err_msg}`); }
			})
			.catch(function (error) {                  
				console.log(error);
				alert(`[E1]\r\n${err_msg}`);
			});			
		},

		cancel: function () {
			window.location.href = '/#/';
		},

		check_email: function (user_email) {
			if (user_email == "" || user_email == null) return;

			let _that = this;
			axios.post('/user/check_email', { email: user_email })
			.then(function (response) {
				_that.email_is_available = response.data.available;
				if (!_that.email_is_available)
				{
					_that.var_style = 'input-style input-error';
					_that.var_tooltip = "This email address is unavailable for registration. Please use a different address."
				}
				else
				{
					_that.var_style = 'input-style';
					_that.var_tooltip = "";
				}
			})
			.catch(function (error) {                  
				console.log(error);
			});
		}
	}
}
</script>

<style scoped>

.input-area {
	text-align: center;
}

.input-card {
	background-color: #fff;
	color:#000;
	width: 25%;
	text-align: center;
	padding-top: 30px;
	padding-left: 65px;
	margin-left: 50px;
	border-radius: 6px;
	display: inline-block;
}

.row-bottom {
	padding-bottom: 50px;
}

.cell-style {
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
}

.label-style {
	color:#5c5c5c;
	font-size: 9pt;
	display: block;
	text-align: left;
}

.input-style {
	border-radius: 3px;
	border-style: solid;
	border-color: #d3d3d3;
	color:#757474;
	border-width: 1px;
	width: 220px;
	min-height: 26px;
	font-size: 9pt;
	background-color: #fff;
}

.input-error {
	color: rgb(202, 53, 53);
	border: solid 2px rgb(202, 53, 53);
}

.error-text {
	color: rgb(202, 53, 53);
}
</style>