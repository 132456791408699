<template class="fvh">
    <section class="bg-dark text-white fvh" style="padding-top: 60px;">
        <div class="row gx-5 justify-content-center">
                <!-- login card -->
                <div class="col-lg-6 col-xl-4">
                    <div class="card mb-5 mb-xl-0">
                        <div class="card-body p-5">
                            <div>
                                <div class="register-text-style">Choose your new password.</div>

                                <div class="input-group" style="padding-top: 20px;">
                                    <input type="password" :class="pass_style" placeholder="Password" :title="match_text" v-model="user.passhash1">
                                </div>
                                <div class="input-group" style="margin-top: 20px;">
                                    <input type="password" :class="pass_style" placeholder="Retype Password" :title="match_text" v-model="user.passhash2">
                                </div>
                                <div class="d-grid" style="margin-top: 20px;"><input type="button" class="btn btn-primary" v-on:click="pass_update(user)" :disabled="save_lock" value="Save" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
</template>
<script>
    import axios from "axios";
    export default {
        name: 'Login',
        components: {},
        data: () => {
            return {
                user: {
                    username: '',
                    passhash: '',
                    passhash1: '',
                    passhash2: ''
                },
                logged_in: false,
                match_text: '',
                pass_style: 'password-style'              
            }
        },
        mounted () 
        {
          let s_user = sessionStorage.getItem('user');
          if (s_user != null)
          {
            let session_user = JSON.parse(s_user);
            this.user = session_user;

            console.log("session user: ", session_user.username);
          }
          setInterval(() => {
            this.pass_change(this.user);
          }, 100);
        },
        methods: {
            pass_update: function (user) 
            {
                let uri = `/user/${user.id}/newpass`;
                console.log(`uri: ${uri}`);
                let params = { passhash1: user.passhash1, passhash2: user.passhash2 }
                axios.post(uri, params)
                .then(function (response) {
                    let pass_obj = response.data;
                    if (pass_obj.object_id == user.id && pass_obj.objects_affected == 1)
                    {
                        window.location.href = '/#/profile';
                        alert("your password has been updated. You may now use with your updated credentials.");
                    }
                })
                .catch(function (error) {
                    alert("Updating password failed. Please check that the passwords match, and try again.");
                    console.log(error);
                });
            },

            pass_change: function(_user)
            {
                if (_user.passhash1 != _user.passhash2)
                {
                    this.pass_style = "password-style input-error";
                    this.match_text = "Your passwords do not match. Check them and try again.";
                    this.save_lock = true;
                }
                else
                {
                    this.pass_style = "password-style";
                    this.match_text = "";
                    this.save_lock = false;
                }
            }
        }
      }
</script>

<style scoped>
    .register-text-style {
        color: #1118;
        text-align: center;
    }
    .password-style {
        border: solid 1px #d3d3d3;
        border-radius: 4px;
        color:#757474;
        width: 350px;
        min-height: 28px;
        background-color: #fff;
    }

    .input-error {
        color: rgb(226, 80, 80);
        border: solid 1px rgb(226, 80, 80);
        background-color: #ffdfdf
    }

    /* .error-text {
        color:rgb(226, 80, 80);
    } */
</style>