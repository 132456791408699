
import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'
import Home from './pages/Home.vue'
import Login from './pages/Login.vue'
import Profile from './pages/Profile.vue'
import Testing from './pages/Testing.vue'
import Order from './pages/Order.vue'
import Password from './pages/Password.vue'
import Contact from './pages/Contact.vue'

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

Vue.use(Router)

const router = new Router({
 routes: [
   {
     path: '/',
     name:'home',
     component: Home,
   },
   {
    path: '/login',
    name:'login',
    component: Login,
  },
  {
    path: '/profile',
    name:'profile',
    component: Profile,
  },
  {
    path: '/testing',
    name:'testing',
    component: Testing,
  },
  {
    path: '/order',
    name:'order',
    component: Order,
  },
  {
    path: '/contact',
    name:'contact',
    component: Contact,
  },
  {
    path: '/matchpass',
    name:'password',
    component: Password,
  }
 ]
})

new Vue({
  components: { VueperSlides, VueperSlide },
  el: '#app',
  render: h => h(App),
  router
})

