<template>
<div id="main">
    
    <!-- Header-->
    <header class="bg-dark py-5">
        <div class="container px-5">
            <div class="row gx-5 align-items-center justify-content-center">
                <div class="col-lg-8 col-xl-7 col-xxl-6">
                    <div class="my-5 text-center text-xl-start">
                        <h3 class="display-6 fw-normal text-nixie mb-2">Tube Testing & Matching</h3>
                        <p class="lead fw-normal text-white mb-4">We provide advanced, reliable tube testing and matching services worldwide. Services for testing transmitter and other high voltage tubes available!</p>
                        <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                            <a class="btn btn-outline-light btn-lg px-4 me-sm-3" href="/#/order">Get Started</a>
                            <!--<a class="btn btn-outline-light btn-lg px-4" href="#test">Learn More</a>-->
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img class="img-fluid rounded-3 my-5" src="assets/IMG_20241125_093202646s.jpg" alt="macro picture of our navy TV/2 tester" /></div>
            </div>
        </div>
    </header>

    <!-- Features section-->
    <section class="bg-grey text-white py-5"  id="test">
        <div class="container px-5 my-5">
            <div class="row gx-5">
                <div class="col-lg-4 mb-5 mb-lg-0">
                    <h3 class="fw-normal text-nixie mb-2">Stop guessing about your tube's characteristics!</h3>
                    <p class="fw-normal text-white" style="margin-top: 26px;">Start testing your tubes today and ensure your equipment performs at it's very best.</p>
                </div>
                <div class="col-lg-8">
                    <div class="row gx-5 row-cols-1 row-cols-md-2">
                        <div class="col mb-5 h-100">
                            <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                                <img src="assets/rx-tube-t.png" style="width: 40px;" />
                            </div>
                            <h2 class="h5 text-nixie">Receiving types</h2>
                            <p class="mb-0">Comprehensive testing of emission, transconductance, filament current, gas content, inter-electrode shorts and more.</p>
                        </div>
                        <div class="col mb-5 h-100">
                            <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                                <img src="assets/tx-tube-t.png" style="width: 40px;" />
                            </div>
                            <h2 class="h5 text-nixie">Transmitting types</h2>
                            <p class="mb-0">We offer testing of power transmitting types with plate input powers up to 2 kilowatts. Testing at plate voltages of up to 3 kilovolts</p>
                        </div>
                        <div class="col mb-5 mb-md-0 h-100">
                            <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                            <h2 class="h5 text-nixie">Gas filled types</h2>
                            <p class="mb-0">We test shunt type gas regulator tubes as well as voltage reference tubes, gas thyratrons, ignitrons, Nixie, and many other gas filled types. </p>
                        </div>
                        <div class="col h-100">
                            <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                            <h2 class="h5 text-nixie">Specialty types & Advanced testing</h2>
                            <p class="mb-0">we can also test a wide variety of magetrons, traveling wave tubes, photocells, photomultiplier tubes and image intensifier tubes</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Pricing section-->
    <section class=" py-5" id="pricing" style="background-color: #606264;">
        <div class="container px-5 my-5">
            <div class="row gx-5 justify-content-center">
                <!-- Pricing card -->
                <div class="col-lg-6 col-xl-4">
                    <div class="card mb-5 mb-xl-0">
                        <div class="card-body p-5">
                            <div class="small text-uppercase fw-bold">Standard</div>
                            <div class="mb-3">
                                <span class="display-4 fw-bold">$4</span>
                                <span class="text-medium">/ tube</span>
                            </div>
                            <ul class="list-unstyled mb-4">
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    <strong>Transconductance</strong>
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    emission <small>(non-destructive)</small>
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    filament current
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    gas content
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    inter-electrode shorts
                                </li>
                                <li class="mb-2 text-muted">
                                    <i class="bi bi-x"></i>
                                    plate curve tolerance grading
                                </li>                                
                                <li class="mb-2 text-muted">
                                    <i class="bi bi-x"></i>
                                    microphonic grading
                                </li>                                
                                <li class="mb-2 text-muted">
                                    <i class="bi bi-x"></i>
                                    printed plate curves 
                                </li>
                            </ul>
                            <div class="d-grid"><a class="btn btn-outline-primary" href="/#/order">Add tubes</a></div>
                        </div>
                    </div>
                </div>
                <!-- Pricing card -->
                <div class="col-lg-6 col-xl-4">
                    <div class="card mb-5 mb-xl-0">
                        <div class="card-body p-5">
                            <div class="small text-uppercase fw-bold">
                                <i class="bi bi-star-fill text-warning"></i>
                                Advanced
                            </div>
                            <div class="mb-3">
                                <span class="display-4 fw-bold">$9</span>
                                <span class="text-medium">/ tube</span>
                            </div>
                            <ul class="list-unstyled mb-4">
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    <strong>Transconductance</strong>
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    emission <small>(non-destructive)</small>
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    filament current
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    gas content
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    inter-electrode shorts
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    plate curve tolerance grading
                                </li>                                
                                <li class="mb-2 text-muted">
                                    <i class="bi bi-x"></i>
                                    microphonic grading
                                </li>                               
                                <li class="mb-2 text-muted">
                                    <i class="bi bi-x"></i>
                                    printed plate curves 
                                </li>
                            </ul>
                            <div class="d-grid"><a class="btn btn-outline-primary" href="/#/order">Add tubes</a></div>
                        </div>
                    </div>
                </div>
                <!-- Pricing card -->
                <div class="col-lg-6 col-xl-4">
                    <div class="card">
                        <div class="card-body p-5">
                            <div class="small text-uppercase fw-bold">Premium</div>
                            <div class="mb-3">
                                <span class="display-4 fw-bold">$14</span>
                                <span class="text-medium">/ tube</span>
                            </div>
                            <ul class="list-unstyled mb-4">
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    <strong>Transconductance</strong>
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    emission <small>(non-destructive)</small>
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    filament current
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    gas content
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    inter-electrode shorts
                                </li>
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    plate curve tolerance grading
                                </li>                                
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    microphonic grading
                                </li>                               
                                <li class="mb-2">
                                    <i class="bi bi-check text-primary"></i>
                                    printed plate curves 
                                </li>
                            </ul>
                            <div class="d-grid"><a class="btn btn-outline-primary" href="/#/order">Add tubes</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</div>
</template>
<script>
import axios from "axios";
export default {
	name: 'Home',
	components: {},
	data: () => {
			return {
				test_types: []
			}
		},
		beforeMount () {
            //this.load_tests();
		},
		mounted () { },
		methods: {

			load_tests: function () { 
				let uri = `/test_types`;
				axios.get(uri)
                .then(function (response) {
                    this.test_types = [response.data.type1, response.data.type2, response.data.type3]
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    
                });
			}
		}
}
</script>
<style scoped>
    .text-medium
    {
        color: #777;
    }
</style>